import React from 'react'
import { RawHtml, handleImgixUrl } from '@kogk/common'
import SliceWrapper from '@cmp/site/SliceWrapper'
import Img from 'gatsby-image'
import style from './Hero.module.scss'

const TEXT_CONTAINER_CLASSES = `
    d-flex
    align-items-center
    justify-content-center
    position-absolute
    w-100
    h-100
  `

const Hero = ({ caption, imageAlt, imageUrlDesktop, imageUrlMobile }) => {
  return (
    <SliceWrapper shouldMove={false} fullBleed>
      <div className={`position-relative pb-3 ${style.container}`}>
        {imageUrlDesktop && (
          <Img
            loading='lazy'
            className='d-none d-sm-block'
            fluid={handleImgixUrl(imageUrlDesktop).gatsbyImageFluid()}
            alt={imageAlt || 'content image'}
          />
        )}

        {imageUrlMobile && (
          <Img
            loading='lazy'
            className='d-sm-none'
            fluid={handleImgixUrl(imageUrlMobile).gatsbyImageFluid()}
            aria-hidden='true'
            alt={imageAlt || 'content image'}
          />
        )}

        <div
          className={TEXT_CONTAINER_CLASSES}
          style={{
            textShadow: '0 2px 40px rgba(0, 0, 0, 0.5)',
            top: 0,
            left: 0
          }}
        >
          <RawHtml
            className='text-center px-2'
            style={{
              color: 'white'
            }}
          >
            {caption}
          </RawHtml>
        </div>
      </div>
    </SliceWrapper>
  )
}

export default Hero
